import * as yup from 'yup';
import 'yup-phone-lite'

export default function signupIdentitySchema() {
  return yup.object({
    gender: yup.string().required('${label} requise').label('Civilité'),
    firstName: yup.string().required('${label} requis').label('Prénom'),
    lastName: yup.string().required('${label} requis').label('Nom'),
    phone: yup.string().required('${label} requis').label('Téléphone'),
    email: yup.string().required('${label} requis').email('${label} invalide').label('Email'),
    linkedin: yup
      .string()
      .nullable()
      .url('${label} invalide')
      .transform(value => (value === '' ? null : value))
      .label('URL LinkedIn'),
    official: yup.boolean().nullable(),
    exam: yup
      .string()
      .nullable()
      .transform(value => (value === '' ? null : value))
      .label('Nom du concours'),
  })
}